import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Emoji from './emoji'

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: `flex`,
      justifyContent: `flex-end`,
      margin: `1.45rem`,
    }}
  >
    <a href="mailto:anikacorpus@gmail.com"
      style={{
        color: `black`,
        textDecoration: `none`,
        fontSize: `18px`
      }}
    >
      <Emoji symbol="📬" label="mail"/>
      Contact Me
    </a>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
